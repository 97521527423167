import { createZustandStore } from "../helpers/zustand"

type FeatureFlagOverridesStore = {
  flagOverrides: { [k: string]: string | number | boolean }
  addFlag: (key: string, value: string | number | boolean) => void
  removeFlag: (key: string) => void
  clear: () => void
}

export const useFeatureFlagOverrides = createZustandStore<FeatureFlagOverridesStore>({
  name: "useFeatureFlagOverrides",
  initializer: (set, get) => ({
    flagOverrides: {},
    addFlag: (key: string, value: string | number | boolean) => {
      set({ flagOverrides: { ...get().flagOverrides, [key]: value } })
    },
    removeFlag: (key: string) => {
      const flags = get().flagOverrides
      delete flags[key]
      set({ flagOverrides: flags })
    },
    clear: () => {
      set({ flagOverrides: {} })
    },
  }),
  persistStore: {
    key: "flag-overrides",
  },
})
