import { createSelectorHooks } from "auto-zustand-selectors-hook"
import { create } from "zustand"
import type { StateCreator, StoreMutatorIdentifier } from "zustand"
import type { DevtoolsOptions } from "zustand/middleware"
import { createJSONStorage, persist } from "zustand/middleware"

import { Persistency } from "@bounce/persistency"

type PersistOptions = {
  key: string
}

type CreateZustandStoreOptions<TStoreValue> = {
  initializer: StateCreator<TStoreValue>
  name: string
  devtoolsOptions?: DevtoolsOptions
  persistStore?: boolean | PersistOptions
}

type StoreMutators = [StoreMutatorIdentifier, unknown][]

export const createZustandStore = <TStoreValue extends Record<string, unknown>>({
  initializer,
  name,
  persistStore,
}: CreateZustandStoreOptions<TStoreValue>) => {
  let store: StateCreator<TStoreValue, StoreMutators, StoreMutators> = initializer

  if (persistStore) {
    store = persist(store, {
      name: typeof persistStore === "object" ? persistStore.key : name,
      storage: createJSONStorage(() => Persistency),
    })
  }

  return createSelectorHooks<TStoreValue>(create(store))
}
