import { getPlatformProperties } from "./helpers/platform"
import { Segment } from "./libs/segment/segment"
import type { SegmentClient } from "./types"

const Analytics: Omit<SegmentClient, "queuedEvents"> = {
  isSetup: Segment.isSetup,
  setup: Segment.setup,
  page: Segment.page,
  screen: Segment.screen,
  track: Segment.track,
  identify: Segment.identify,
  reset: Segment.reset,
  getAnonymousId: Segment.getAnonymousId,
}

export { Analytics, getPlatformProperties }
