"use client"
import { ConsentManagerBuilder } from "@segment/consent-manager"
import inEU from "@segment/in-eu"
import { isEqual } from "lodash"
import React, { useCallback, useEffect, useRef } from "react"

import { Analytics, LegalEvents } from "@bounce/analytics"
import { useConfigValue, CommonFeatureFlag } from "@bounce/configcat"
import { useTranslation } from "@bounce/i18n"
import { PersistencyKeys } from "@bounce/persistency"

import CookieConsentDialog from "./components/CookieConsentDialog"
import CookiePreferences from "./components/CookiePreferences"
import type { UIImplementation } from "./components/types"
import { useCookiePreferences } from "./contexts/CookiePreferences"
import { ALLOW_ALL_PREFERENCES, preferenceMapping } from "./helpers/helpers"
import type { Action, ConsentUIProps, CookiesConsentManagerProps, PreferenceToggle } from "./helpers/types"

export const CookiesConsentManager =
  (platform: UIImplementation) =>
  ({
    writeKey,
    config,
    requiresConsent = inEU,
    onAnalyticsLoad,
    cookieAttributes: cookieConfig,
  }: CookiesConsentManagerProps) => {
    return (
      <ConsentManagerBuilder
        cookieName={PersistencyKeys.COOKIE_PREFERENCES}
        cookieAttributes={{ sameSite: "strict", ...cookieConfig }}
        initialPreferences={ALLOW_ALL_PREFERENCES}
        shouldRequireConsent={requiresConsent}
        mapCustomPreferences={preferenceMapping}
        writeKey={writeKey}
        shouldReload={false}
        /* Because we are manually loading the Segment SDK,
           we need to pass "devMode" as true to avoid the Consent Manager to load the Segment SDK */
        devMode
        // eslint-disable-next-line no-console
        onError={e => console.error(e)}
        defaultDestinationBehavior="imply">
        {props => <ConsentUI {...props} config={config} implementation={platform} onAnalyticsLoad={onAnalyticsLoad} />}
      </ConsentManagerBuilder>
    )
  }

const ConsentUI = ({
  preferences,
  destinations,
  destinationPreferences,
  newDestinations,
  setPreferences: setSegmentPreferences,
  saveConsent,
  isConsentRequired,
  implementation,
  config,
  onAnalyticsLoad,
}: ConsentUIProps) => {
  const { t } = useTranslation("legal")
  const { preferences: storedPreferences, currentDialog, setCurrentDialog, setPreferences } = useCookiePreferences()
  const [consentBehaviorEnabled, isReady] = useConfigValue({
    setting: CommonFeatureFlag.CookieConsentBehavior,
    defaultValue: false,
    user: { id: "not_set", email: undefined, custom: { platform: "web" } },
  })
  const isLoadingRef = useRef(false)

  const conditionallyLoadAnalytics = async () => {
    if (isLoadingRef.current) return

    isLoadingRef.current = true
    let isAnythingEnabled = false

    if (!consentBehaviorEnabled) {
      await Analytics.setup(config)
      onAnalyticsLoad?.()
      return
    }

    const integrationsToLoad: Record<string, boolean> = {}

    for (const destination of destinations) {
      const isEnabled = Boolean(destinationPreferences[destination.id])
      if (isEnabled) {
        isAnythingEnabled = true
      }

      if (destination.id === "Actions Amplitude") {
        integrationsToLoad["Amplitude (Actions)"] = isEnabled
      }

      integrationsToLoad[destination.id] = isEnabled
    }

    if (isAnythingEnabled) {
      await Analytics.setup({ ...config, integrationsToLoad })
      onAnalyticsLoad?.()
    }

    isLoadingRef.current = false
  }

  useEffect(() => {
    if (!isReady) return

    if (!isConsentRequired) {
      saveConsent(ALLOW_ALL_PREFERENCES, false, true)
      setCurrentDialog(false)

      return
    }

    if (newDestinations.length > 0 && !storedPreferences) {
      setCurrentDialog("dialog")

      Analytics.track(LegalEvents.CookieConsentDialogDisplayed)

      return
    }

    if (storedPreferences && !isEqual(storedPreferences, preferences)) {
      saveConsent(storedPreferences, false, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  useEffect(() => {
    if (newDestinations.length) return

    setPreferences(preferences)
  }, [preferences, setPreferences, newDestinations])

  useEffect(() => {
    if (Analytics.isSetup()) return

    void conditionallyLoadAnalytics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const accept: Action = {
    text: t("cookiePreferences.action.accept", "Accept all"),
    handler: () => {
      saveConsent(ALLOW_ALL_PREFERENCES, false, true)
      Analytics.track(LegalEvents.CookiesAllAccepted, {
        dialogType: currentDialog,
      })
      setCurrentDialog(false)
    },
  }

  const manage: Action = {
    text: t("cookiePreferences.action.managePreferences", "Manage Preferences"),
    handler: () => {
      setCurrentDialog("preferences")
      Analytics.track(LegalEvents.CookiesManagePreferences, {
        dialogType: currentDialog,
      })
    },
  }

  const save: Action = {
    text: t("cookiePreferences.action.savePreferences", "Save preferences"),
    handler: () => {
      saveConsent(preferences, false, true)
      Analytics.track(LegalEvents.CookiesPreferencesSaved, preferences)
      setCurrentDialog(false)
    },
  }

  const togglePref = useCallback<PreferenceToggle>(
    prefs => category => {
      setSegmentPreferences({
        [category]: !prefs[category],
      })
    },
    [setSegmentPreferences],
  )

  switch (currentDialog) {
    case "dialog":
      return <CookieConsentDialog actions={{ accept, manage }} implementation={implementation} />
    case "preferences":
      return (
        <CookiePreferences
          actions={{ accept, save }}
          preferences={preferences}
          togglePref={togglePref}
          implementation={implementation}
        />
      )
    default:
      return null
  }
}
