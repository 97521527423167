import { Analytics } from "@bounce/analytics"
import { Persistency, PersistencyKeys } from "@bounce/persistency"
import { isABot } from "@bounce/util"

let inMemoryConfigcatAnonymousId: string | undefined

/**
 * Get the configcat id from persistency
 */
const getPersistedConfigcatAnonymousId = async (): Promise<string | null> => {
  return await Persistency.getItem(PersistencyKeys.CONFIGCAT_ANONYMOUS_ID)
}

/**
 * Persist the configcat id
 */
const persistConfigcatAnonymousId = async (id: string): Promise<void> => {
  await Persistency.setItem(PersistencyKeys.CONFIGCAT_ANONYMOUS_ID, id)
}

/**
 * Get the configcat id, can't be run before using initConfigcatAnonymousId
 */
export const getConfigcatAnonymousId = (): string => {
  if (!inMemoryConfigcatAnonymousId) {
    throw new Error("inMemoryConfigcatAnonymousId is not initialized when calling getConfigcatAnonymousId()")
  }

  return isABot() ? "bot-user" : inMemoryConfigcatAnonymousId
}

/*
 * Initialize the initConfigcatAnonymousId with the persisted segment anonymous id if available
 * else use the session id
 */
export const initConfigcatAnonymousId = async (fallbackId: string) => {
  if (typeof window === "undefined") return fallbackId

  const persistedConfigcatId = await getPersistedConfigcatAnonymousId()
  if (persistedConfigcatId) {
    inMemoryConfigcatAnonymousId = persistedConfigcatId
    return inMemoryConfigcatAnonymousId
  }

  const segmentAnonymousId = await Analytics.getAnonymousId()
  inMemoryConfigcatAnonymousId = segmentAnonymousId || fallbackId

  await persistConfigcatAnonymousId(inMemoryConfigcatAnonymousId)
  return inMemoryConfigcatAnonymousId
}
