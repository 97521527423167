import type { BasicObject } from "./types"

export const asPromise = <T>(res: Promise<T> | T) => {
  return isPromise(res) ? res : Promise.resolve(res)
}

export const isFunction = (obj: any): obj is Function => typeof obj === "function"
export const isObject = (obj: any): obj is BasicObject => obj !== null && typeof obj === "object"
export const isPromise = (value: any): value is PromiseLike<any> => isObject(value) && isFunction(value.then)

/**
 * Get the first value of object or array
 * @example
 * first({ name: "jack", country: "US"}) // "jack"
 */
export const first = <T>(value?: unknown): T | undefined => {
  if (Array.isArray(value)) return value[0]
  if (isObject(value)) return Object.values(value as object)[0]

  return undefined
}

export const isSet = <T>(props: T | undefined, keys?: (keyof T)[]): props is Required<T> => {
  if (!props) {
    return false
  }

  keys = keys ?? (Object.keys(props) as (keyof T)[])

  let isMissingValue = false
  keys.map(k => {
    isMissingValue = isMissingValue || !props[k]
  })

  return !isMissingValue
}

/**
 * Removes all fields that are undefined, null or empty string
 *
 * @param obj The object to clean
 * @param recursive
 */
export const clean = <T extends BasicObject<any>>(obj: T, recursive = true): Partial<T> => {
  for (const propName in obj) {
    const propValue = obj[propName]

    if (propValue === null || propValue === undefined || propValue === "") {
      delete obj[propName]
    } else if (typeof propValue === "object" && recursive) {
      // Recurse here if the property is another object.
      clean<Partial<T>>(propValue)
    }
  }
  return obj
}

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  if (value === null || value === undefined) return false
  return true
}

type Falsy = null | undefined | false | "" | 0
export const notFalsy = <TValue>(value: TValue | Falsy): value is Exclude<TValue, Falsy> => !!value

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms, undefined))
}

export const nop = <TValue>(value?: TValue) => value

export const nopAsync = async <TValue>(value?: TValue) => value

export function randomId(length = 5) {
  let text = ""
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}
