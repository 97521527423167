import { getConfig } from "@bounce/config"

import { PREVIEW_config } from "./config.preview"
import { PRODUCTION_config } from "./config.production"
import type { MarketingAppConfig } from "./config.types"

let config: MarketingAppConfig
let USER_config: MarketingAppConfig | undefined
try {
  USER_config = require("./config.user.ts").USER_config
} catch (error) {
  USER_config = undefined
}

const loadConfig = (env: string | undefined) => {
  config = getConfig<MarketingAppConfig>({
    env,
    hostname: typeof window !== "undefined" ? window.location.hostname : undefined,
    configs: {
      production: PRODUCTION_config,
      preview: PREVIEW_config,
      user: USER_config,
    },
  })
}

loadConfig(process.env.ENVIRONMENT)

export { config }
