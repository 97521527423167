import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { v4 as uuid } from "uuid"

import type { UserMatchers } from "@bounce/configcat"
import { useConfigValue, initConfigcatAnonymousId, useFeatureFlags } from "@bounce/configcat"

export enum ConfigValue {
  BackpacksPricingProductMultipliers = "config_backpacks_pricing_product_multipliers",
  BackpacksPricingProductMultipliersForEurope = "config_europe_backpacks_pricing_product_multipliers",
}

export enum FeatureFlag {
  Backpacks = "feature_backpacks",
  MarketingExitSurvey = "feature_marketing_exit_survey",
  SeeNearbyStorageSearchCta = "feature_search_cta_marketing_copy",
  MapOnCityPage = "feature_map_on_city_page",
  StickyAppBanner = "feature_sticky_app_banner_on_marketing_pages",
  NewHomepageHeadline = "feature_new_homepage_headline",
}

const fallbackAnonymousId = uuid()

const useUserId = () => {
  const { data: userId } = useQuery({
    queryKey: ["ConfigCat", "userId"],
    queryFn: async () => initConfigcatAnonymousId(fallbackAnonymousId),
  })

  return userId
}

export const useAllFeatureFlags = () => {
  const id = useUserId()

  const featureFlagsQuery = useFeatureFlags({
    id: id || "",
    email: undefined,
    enums: {
      config: ConfigValue,
      flags: FeatureFlag,
    },
  })

  const featureFlags = id ? featureFlagsQuery.featureFlags : undefined

  return { featureFlags }
}

export const useFeatureFlagUser = (): UserMatchers => {
  const id = useUserId()
  const router = useRouter()

  return {
    id: id || "",
    email: undefined,
    custom: {
      platform: "web",
      utmSource: router.query.utm_source?.toString(),
    },
  }
}

export const useFeatureFlag = (flag: FeatureFlag, defaultValue: boolean = false) => {
  const user = useFeatureFlagUser()

  return useConfigValue({ setting: flag, defaultValue, user })
}

export const useMultiVariantFlag = <T extends string>(flag: FeatureFlag | ConfigValue, defaultValue: T) => {
  const user = useFeatureFlagUser()

  return useConfigValue<T>({ setting: flag, defaultValue, user })
}
