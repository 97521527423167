import Image from "next/image"
import React from "react"

import { Button } from "@bounce/web-components"

import type { CookieConsentActions } from "../../helpers/types"
import type { HeaderProps } from "../types"

import cookieImage from "@bounce/assets/images/cookie.webp"

const Actions = ({ accept, manage }: CookieConsentActions) => {
  return (
    <>
      <Button as="button" size="sm" variant="ghost" color="primary" onClick={manage.handler} className="w-full">
        {manage.text}
      </Button>
      <Button as="button" size="sm" onClick={accept.handler} className="w-full">
        {accept.text}
      </Button>
    </>
  )
}

const Header = ({ title, children }: HeaderProps) => {
  return (
    <div className="pb-4 text-center">
      <Image
        alt="cookie"
        src={cookieImage}
        unoptimized
        priority
        height={120}
        width={120}
        className="mx-auto h-32 w-32 self-center object-contain object-center "
      />
      <h5 className="mt-4 text-h5">{title}</h5>
      <p className="mt-2 text-neutral-800 text-body1">{children}</p>
    </div>
  )
}

export { Actions, Header }
