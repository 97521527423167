import "../global.css"
import "swiper/css"

import { PrismicPreview } from "@prismicio/next"
import ICU from "i18next-icu"
import type { AppProps } from "next/app"
import { appWithTranslation } from "next-i18next"
import { useMemo } from "react"

import { ConfigCatProvider, OverrideBehaviour } from "@bounce/configcat"
import { getLocale } from "@bounce/i18n"
import { ReferralsProvider } from "@bounce/referrals"
import { PreviewBanner } from "@bounce/web-components"

import nextI18nextConfig from "../../next-i18next.config"
import { DatadogProvider } from "../components/Generic/Analytics/DatadogProvider"
import { SegmentProvider } from "../components/Generic/Analytics/SegmentProvider"
import { ImageFilters } from "../components/Generic/ImageFilters/ImageFilters"
import { PreCacheServiceWorker } from "../components/Generic/PreCacheServiceWorker"
import BackwardsCompatibilityPostProcessor from "../libs/i18n/BackwardsCompatibilityPostProcessor"
import { fixI18nextImports } from "../utils/i18n/fixI18nextImports"
import CookiesConsent from "@/components/Generic/CookiesConsent"
import { config } from "@/config"
import { ReactQueryProvider } from "@/libs/react-query/provider"

const CONSENTLESS_ROUTES = ["/legal/[uid]/embed", "/slice-simulator"]

const MarketingApp = ({
  Component,
  pageProps,
  router: { locale: language, route, query: routerQuery, isReady, isPreview },
}: AppProps) => {
  fixI18nextImports()

  // Forward the url params to the ReferralsProvider when the app is ready
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const urlParams = useMemo(() => routerQuery, [isReady])
  const locale = getLocale("language", language ?? "en")
  const renderThirdParty = typeof window !== "undefined" && locale
  const shouldRenderConsentManager = !CONSENTLESS_ROUTES.includes(route)

  return (
    <ReactQueryProvider>
      <ConfigCatProvider config={config.configCat} overrideBehaviour={OverrideBehaviour.RemoteOverLocal}>
        <ReferralsProvider urlParams={urlParams}>
          {config.id === "PREVIEW" && (
            <PreviewBanner
              prNumber={process.env.VERCEL_GIT_PULL_REQUEST_ID}
              href={`${config.prLinks}/${process.env.VERCEL_GIT_PULL_REQUEST_ID}`}
            />
          )}
          <ImageFilters />
          <Component {...pageProps} />
          {renderThirdParty && (
            <>
              <SegmentProvider locale={locale.locale} route={route} pageProps={pageProps} />
              <DatadogProvider locale={locale.locale} route={route} />
              <PreCacheServiceWorker />
            </>
          )}
          {isReady && shouldRenderConsentManager && <CookiesConsent />}
          {isPreview && <PrismicPreview repositoryName={config.prismic.repositoryName} />}
        </ReferralsProvider>
      </ConfigCatProvider>
    </ReactQueryProvider>
  )
}

export default appWithTranslation(MarketingApp, {
  ...nextI18nextConfig,
  use: [ICU, BackwardsCompatibilityPostProcessor],
})
