import { DEFAULT_config } from "./config.default"
import type { MarketingAppConfig } from "./config.types"
import { featureFlagOverrides } from "../libs/configcat/configCat"

export const PRODUCTION_config: MarketingAppConfig = {
  ...DEFAULT_config,
  id: "PRODUCTION",
  domain: process.env.NEXT_PUBLIC_BOUNCE_DOMAIN || "usebounce.com",
  cookieDomain: process.env.NEXT_PUBLIC_BOUNCE_DOMAIN || "usebounce.com",
  devMode: false,
  apis: {
    marketing: "https://usebounce.com",
    bounce: "https://graphql.usebounce.com",
    packageAcceptance: "https://package-acceptance-api.usebounce.com",
  },
  intercom: {
    luggageStorageAppId: "x85uh933",
    packagesAppId: "x85uh933",
    partnerAppId: "moqwm5ew",
  },
  datadog: {
    ...DEFAULT_config.datadog,
    env: "prod",
    enabled: true,
    tracingOrigins: ["https://graphql.usebounce.com"],
    version: DEFAULT_config.commitSha?.slice(0, 6),
    sampleRate: 50,
  },
  segment: {
    writeKey: "KeG8C906eJRXss3Qe2kkfR77xWIVMHPf",
    cdnURL: "https://seg-cdn.usebounce.com",
    proxy: "https://seg-api.usebounce.com/v1",
    debug: false,
    logger: false,
  },
  configCat: {
    key: "1bDZCOyoD0WGGJ9ql6GYzw/0wVDfC4ESEiobtrcny0IZA",
    flagOverrides: featureFlagOverrides,
  },
  ashby: {
    key: process.env.ASHBY_KEY,
  },
}
