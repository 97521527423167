const PersistencyKeys = {
  AUTH_TOKEN_KEY: "AUTH_TOKEN_KEY",
  AUTH_IS_FIRST_LOGIN: "AUTH_IS_FIRST_LOGIN",
  CONFIGCAT_ANONYMOUS_ID: "CONFIGCAT_ANONYMOUS_ID",
  USER_KEY: "USER_KEY",
  LANG_KEY: "LANG_KEY",
  ENVIRONMENT_KEY: "ENVIRONMENT_KEY",
  DEBUG_MODE_KEY: "DEBUG_MODE_KEY",
  EXPO_NOTIFICATION_TOKEN: "EXPO_NOTIFICATION_TOKEN_KEY",
  EXPO_NOTIFICATION_TOKEN_SENT_TO_SERVER: "EXPO_NOTIFICATION_TOKEN_SENT_TO_SERVER_KEY",
  PROMPT_DOWNLOAD_THE_APP: "PROMPT_DOWNLOAD_THE_APP_V2",
  PREFERRED_PAYMENT_METHOD_TYPE: "PREFERRED_PAYMENT_METHOD_TYPE",
  PREFERRED_STORE_ID: "PREFERRED_STORE",
  SHOW_REWARDS_BADGE_TO_PARTNER: "SHOW_REWARDS_BADGE_TO_PARTNER",
  SHOW_NEW_TO_BOUNCE_MODAL: "SHOW_NEW_TO_BOUNCE_MODAL",
  COOKIE_PREFERENCES: "COOKIE_PREFERENCES",
  SHOW_PARTNER_WALKINS_MODAL: "SHOW_PARTNER_WALKINS_MODAL",
  SHOW_UPDATED_PACKAGE_CHECKOUT_MODAL: "SHOW_UPDATED_PACKAGE_CHECKOUT_MODAL",
  SHOW_STORE_DETAILS_SIGNAGE_BADGE: "SHOW_STORE_DETAILS_SIGNAGE_BADGE",
  SHOW_UPDATED_PARTNER_PACKAGE_CHECKOUT_MODAL: "SHOW_UPDATED_PARTNER_PACKAGE_CHECKOUT_MODAL",
  SHOW_FEATURE_BADGE: "SHOW_FEATURE_BADGE",
  SELECTED_ACCOUNT_ID: "SELECTED_ACCOUNT_ID",
  ONBOARDING_TASKS_COMPLETED: "ONBOARDING_TASKS_COMPLETED",
  SHOW_BRAND_AWARENESS_MODAL: "SHOW_BRAND_AWARENESS_MODAL",
  SKIP_VERIFY_EXPIRATION_TIMESTAMP: "SKIP_VERIFY_EXPIRATION_TIMESTAMP",
  PACKAGE_DELIVERY_WAITLIST_JOINED: "PACKAGE_DELIVERY_WAITLIST_JOINED",
  PARTNER_TOURS: "PARTNER_TOURS",
  SHOW_WALK_IN_DEVIATION_MODAL: "SHOW_WALK_IN_DEVIATION_MODAL",
  SHOW_CASH_REQUESTED_SURVEY: "SHOW_CASH_REQUESTED_SURVEY",
  SHOW_WALK_INS_UNLOCKED_MODAL: "SHOW_WALK_INS_UNLOCKED_MODAL",
  SHOW_STORE_PICTURES_GUIDELINES: "SHOW_STORE_PICTURES_GUIDELINES",
  SHOW_BAG_TYPES_MODAL: "SHOW_BAG_TYPES_MODAL",
} as const

export { PersistencyKeys }
